import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../../../../src/common/components/Layout'
import { SeoData } from '../../../../src/common/types/Seo'
import { MenuPrincipal } from '../../constants/menu'
import { useSelectedMenu } from '../../helpers/customHooks/useSelectedMenu'
import CanalEticoContents from '../../../../src/common/static/canal-etico'
import styles from './index.module.scss'

interface Props {
  data: {
    seoData: SeoData
  }
}
const canalEtico: React.FC<Props> = ({ data }) => {
  useSelectedMenu(MenuPrincipal.HOME)
  return (
    <Layout selector={false} seoData={data.seoData}>
      <CanalEticoContents styles={styles} />
    </Layout>
  )
}
export default canalEtico

export const pageQuery = graphql`
  query canalEticoQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
