import React from 'react'
import { locale, textWithLocale } from '../i18n'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  styles: { [k: string]: string }
}

const getCanalUrl = (): string => {
  switch (process.env.GATSBY_WEB) {
    case 'rodi':
      return 'https://canal-etico.lant-abogados.com/Specific/MVistaPublica?codiClient=MDAxOTkw&secure=185708.0'
    case 'elpaso':
      return 'https://canal-etico.lant-abogados.com/Specific/MVistaPublica?codiClient=OTEyNTI0&secure=8.486537E7'
    default:
      return '#'
  }
}

const CanalEticoCa = ({ styles }: Props) => (
  <div className={styles.container}>
    <h1 className={styles.title}>Canal étic</h1>
    <p>
      El present canal està dedicat exclusivament per a dirigir denúncies per
      incompliments legals de l'organització o les seves persones integrants, en
      els termes i condicions de la Llei 2/2023, de 20 de febrer, reguladora de
      la protecció de les persones que informin sobre infraccions normatives i
      de lluita contra la corrupció.
    </p>
    <p>
      Si té qualsevol dubte sobre altres qüestions pot contactar-nos a través
      d'altres canals disponibles en la pàgina web.
    </p>
    <h2 className={styles.section_title}>
      <strong>Què es consideren incompliments normatius?</strong>:
    </h2>
    <p>Sense ànim excloent ni limitatiu, es consideren:</p>
    <ul>
      <li>Fraus i estafes</li>
      <li>Blanqueig de capitals</li>
      <li>Evasió o elusió d’impostos</li>
      <li>Delictes contra la propietat intel·lectual o industrial</li>
      <li>Competència deslleial</li>
      <li>Delictes contra el mercat o les persones consumidores</li>
      <li>Corrupció interna</li>
      <li>Irregularitats amb la Seguretat Social o l'Agència Tributària</li>
      <li>Incompliment de la normativa sobre protecció de dades</li>
      <li>Revelació de secrets empresarials</li>
      <li>Malversació</li>
      <li>Delictes contra el medi ambient i la salut pública</li>
      <li>Vulneració de drets de les persones treballadores</li>
    </ul>
    <p>
      Algunes de les situacions que es podrien denunciar per aquesta via són:
    </p>
    <ul>
      <li>
        Possibles conductes i comportaments considerats il·legals amb
        l'acompliment del treball
      </li>
      <li>Presumpta gestió lucrativa de recursos de l'empresa</li>
      <li>Situacions de discriminació o abús sexual</li>
      <li>Pràctiques de corrupció, robatoris, fraus, etc.</li>
    </ul>
    <h2 className={styles.section_title}>
      <strong>Qui pot presentar una comunicació?</strong>:
    </h2>
    <p>
      Qualsevol persona de l'empresa i aquelles persones externes que estiguin o
      hagin estat vinculades o relacionades amb la mateixa en un context laboral
      o professional.
    </p>
    <h2 className={styles.section_title}>
      <strong>Com puc realitzar una comunicació?</strong>:
    </h2>
    <p>
      Es pot cursar de manera verbal, escrita o presencial seguint els passos
      indicats en el formulari del Canal confidencial de denúncies. Així mateix,
      la comunicació pot ser anònima.
    </p>
    <h2 className={styles.section_title}>
      <strong>Com es tramitarà la comunicació?</strong>:
    </h2>
    <p>
      Es tramitarà garantint la confidencialitat i prohibició de represàlies a
      la persona informant.
    </p>
    <h2 className={styles.section_title}>
      <strong>Quins principis assisteixen a la persona informant?</strong>:
    </h2>
    <p>
      El sistema intern d'informació reuneix les garanties de confidencialitat,
      no represàlies, respecte a la independència, la protecció de dades, secret
      de les comunicacions i les pràctiques correctes de seguiment, recerca i
      protecció de la persona informant.
    </p>
    <p className={styles.link_wrapper}>
      <a
        target="_blank"
        className={styles.link}
        href={getCanalUrl()}
        rel="noreferrer">
        Accedir al canal ètic
        <FontAwesomeIcon icon={faChevronRight} className={styles.link_icon} />
      </a>
    </p>
  </div>
)

const CanalEticoEs = ({ styles }: Props) => (
  <div className={styles.container}>
    <h1 className={styles.title}>Canal ético</h1>
    <p>
      El presente canal está dedicado exclusivamente para dirigir denuncias por
      incumplimientos legales de la organización o sus personas integrantes, en
      los términos y condiciones de la Ley 2/2023, de 20 de febrero, reguladora
      de la protección de las personas que informen sobre infracciones
      normativas y de lucha contra la corrupción.
    </p>
    <p>
      Si tiene cualquier duda sobre otras cuestiones puede contactarnos a través
      de otros canales disponibles en la página web.
    </p>
    <h2 className={styles.section_title}>
      <strong>¿Qué se consideran incumplimientos normativos?</strong>:
    </h2>
    <p>Sin ánimo excluyente ni limitativo, se consideran:</p>
    <ul>
      <li>
        Fraudes y estafas-Blanqueo de capitales-Evasión o elusión de impuestos
      </li>
      <li>Delitos contra la propiedad intelectual o industrial</li>
      <li>
        Competencia desleal-Delitos contra el mercado o las personas
        consumidoras
      </li>
      <li>
        Corrupción interna-Irregularidades con la Seguridad Social o la Agencia
        Tributaria
      </li>
      <li>Incumplimiento de la normativa sobre protección de datos</li>
      <li>Revelación de secretos empresariales</li>
      <li>Malversación</li>
      <li>Delitos contra el medio ambiente y la salud pública</li>
      <li>Vulneración de derechos de las personas trabajadoras</li>
    </ul>
    <p>Algunas de las situaciones que se podrían denunciar por esta vía son:</p>
    <ul>
      <li>
        Posibles conductas y comportamientos considerados ilegales con el
        desempeño del trabajo
      </li>
      <li>Presunta gestión lucrativa de recursos de la empresa</li>
      <li>Situaciones de discriminación o abuso sexual</li>
      <li>Prácticas de corrupción, robos, fraudes, etc.</li>
    </ul>
    <h2 className={styles.section_title}>
      <strong>¿Quién puede presentar una comunicación?</strong>:
    </h2>
    <p>
      Cualquier persona de la empresa y aquellas personas externas que estén o
      hayan estado vinculadas o relacionadas a con la misma en un contexto
      laboral o profesional.
    </p>
    <h2 className={styles.section_title}>
      <strong>¿Cómo puedo realizar una comunicación?</strong>:
    </h2>
    <p>
      Se puede cursar de forma verbal, escrita o presencial siguiendo los pasos
      indicados en el formulario del Canal confidencial de denuncias. Asimismo,
      la comunicación puede ser anónima.
    </p>
    <h2 className={styles.section_title}>
      <strong>¿Cómo se tramitará la comunicación?</strong>:
    </h2>
    <p>
      Se tramitará garantizando la confidencialidad y prohibición de represalias
      a la persona informante.
    </p>
    <h2 className={styles.section_title}>
      <strong>¿Qué principios asisten a la persona informante?</strong>:
    </h2>
    <p>
      El sistema interno de información reúne las garantías de confidencialidad,
      no represalias, respeto a la independencia, la protección de datos,
      secreto de las comunicaciones y las prácticas correctas de seguimiento,
      investigación y protección de la persona informante.{' '}
    </p>
    <p className={styles.link_wrapper}>
      <a
        target="_blank"
        className={styles.link}
        href={getCanalUrl()}
        rel="noreferrer">
        Acceder al canal ético
        <FontAwesomeIcon icon={faChevronRight} className={styles.link_icon} />
      </a>
    </p>
  </div>
)

const CanalEticoContents = ({ ...props }: Props) => {
  switch (locale) {
    case 'es':
      return <CanalEticoEs {...props} />
    case 'ca':
      return <CanalEticoCa {...props} />
    default:
      return null
  }
}

export default CanalEticoContents
